<template>
  <!-- filter area -->
  <div class="p-fluid p-grid p-jc-end print-hidden">
    <!-- right -->
    <div class="p-col-12 p-sm-3" v-if="userLevel == 'AC'">
      <Dropdown
        v-model="areaId"
        :options="areas"
        optionLabel="areaName"
        optionValue="codeId"
        placeholder="กรองข้อมูล พื้นที่"
        :filter="true"
        @change="fetchData"
      />
    </div>
    <div class="p-col-11 p-sm-2">
      <router-link to="/dashboard">
        <Button label="กลับสู่หน้าหลัก" icon="pi pi-arrow-left"
      /></router-link>
    </div>
    <div class="p-col-1">
      <Button icon="pi pi-print" @click="print" />
    </div>
  </div>
  <!-- / filter area -->
  <Fieldset>
    <template #legend>
      <i class="pi pi-check" style="fontsize: 2rem"></i>
      10 ตัวแปรที่ส่งผลกับความสามารถในการทำงาน
    </template>
    <div>
      การวิเคราะห์ข้อมูลตัวแปรที่ส่งผลกับความสามารถในการทำงานของผู้สูงอายุ (Work Ability Index-WAI) โดยเอาปัจจัยในกลุ่มของข้อมูลคัดกรองภาวะสุขภาพ (ส่วนที่ 3) มาทำการวิเคราะห์ด้วยการทำ Feature Selection ได้ผลลัพธ์ตัวแปร 10 ลำดับแรกดังต่อไปนี้
    </div>
  </Fieldset>
  <Fieldset :legend="images['ten_valiable_1_1']">
    <img :src="plots['ten_valiable_1_1']" :style="graphStyle" />
  </Fieldset>

  <Fieldset :legend="images['ten_valiable_1_2']">

      <img :src="plots['ten_valiable_1_2']" :style="graphStyle" />
  </Fieldset>

  <Fieldset :legend="images['ten_valiable_2_1']">

      <img :src="plots['ten_valiable_2_1']" :style="graphStyle" />
  </Fieldset>

  <Fieldset :legend="images['ten_valiable_2_2']">

      <img :src="plots['ten_valiable_2_2']" :style="graphStyle" />
  </Fieldset>

  <Fieldset :legend="images['ten_valiable_3_1']">

      <img :src="plots['ten_valiable_3_1']" :style="graphStyle" />
  </Fieldset>

  <Fieldset :legend="images['ten_valiable_3_2']">

      <img :src="plots['ten_valiable_3_2']" :style="graphStyle" />
  </Fieldset>

  <Fieldset :legend="images['ten_valiable_4_1']">

      <img :src="plots['ten_valiable_4_1']" :style="graphStyle" />
  </Fieldset>

  <Fieldset :legend="images['ten_valiable_4_2']">

      <img :src="plots['ten_valiable_4_2']" :style="graphStyle" />
  </Fieldset>

  <Fieldset :legend="images['ten_valiable_5_1']">

      <img :src="plots['ten_valiable_5_1']" :style="graphStyle" />
  </Fieldset>

  <Fieldset :legend="images['ten_valiable_5_2']">

      <img :src="plots['ten_valiable_5_2']" :style="graphStyle" />
  </Fieldset>

  <Fieldset :legend="images['ten_valiable_6_1']">

      <img :src="plots['ten_valiable_6_1']" :style="graphStyle" />
  </Fieldset>

  <Fieldset :legend="images['ten_valiable_6_2']">

      <img :src="plots['ten_valiable_6_2']" :style="graphStyle" />
  </Fieldset>

  <Fieldset :legend="images['ten_valiable_7_1']">

      <img :src="plots['ten_valiable_7_1']" :style="graphStyle" />
  </Fieldset>

  <Fieldset :legend="images['ten_valiable_7_2']">

      <img :src="plots['ten_valiable_7_2']" :style="graphStyle" />
  </Fieldset>

  <Fieldset :legend="images['ten_valiable_8_1']">

      <img :src="plots['ten_valiable_8_1']" :style="graphStyle" />
  </Fieldset>

  <Fieldset v-if="areaId === 'ALL'" :legend="images['ten_valiable_8_2']">
      <img v-if="areaId === 'ALL'" :src="plots['ten_valiable_8_2']" :style="graphStyle" />
  </Fieldset>

  <Fieldset :legend="images['ten_valiable_9_1']">

      <img :src="plots['ten_valiable_9_1']" :style="graphStyle" />
  </Fieldset>

  <Fieldset :legend="images['ten_valiable_9_2']">

      <img :src="plots['ten_valiable_9_2']" :style="graphStyle" />
  </Fieldset>

  <Fieldset :legend="images['ten_valiable_10_1']">

      <img :src="plots['ten_valiable_10_1']" :style="graphStyle" />
  </Fieldset>

    <Fieldset v-if="areaId != 'ALL'" :legend="images['ten_valiable_10_2']">
      <img v-if="areaId != 'ALL'" :src="plots['ten_valiable_10_2']" :style="graphStyle" />
    </Fieldset>


  <ModalHealthSum :is-open="isModal" @dimmer="() => (isModal = false)" />
  <MoveToTopPage />
</template>


<script>
import axios from 'axios'
import * as _ from 'lodash'
import * as am4core from '@amcharts/amcharts4/core'
import * as am4charts from '@amcharts/amcharts4/charts'
import am4themes_animated from '@amcharts/amcharts4/themes/animated'

import { imageEncode } from '../../Utility'
import { mixins } from '../Mixins'

import MoveToTopPage from '../../components/MovetoTop'
import ModalHealthSum from './ModalHealthSum'
import DividerWord from './DividerWord'
// Themes begin
am4core.useTheme(am4themes_animated)
// Themes end

export default {
  mixins: [mixins],
  layout: 'Private',
  data() {
    return {
      areaId: 'ALL',
      plots: {
        ten_valiable_1_1: null,
        ten_valiable_1_2: null,
        ten_valiable_2_1: null,
        ten_valiable_2_2: null,
        ten_valiable_3_1: null,
        ten_valiable_3_2: null,
        ten_valiable_4_1: null,
        ten_valiable_4_2: null,
        ten_valiable_5_1: null,
        ten_valiable_5_2: null,
        ten_valiable_6_1: null,
        ten_valiable_6_2: null,
        ten_valiable_7_1: null,
        ten_valiable_7_2: null,
        ten_valiable_8_1: null,
        ten_valiable_8_2: null,
        ten_valiable_9_1: null,
        ten_valiable_9_2: null,
        ten_valiable_10_1: null,
        ten_valiable_10_2: null
      },
      images: {
        ten_valiable_1_1: '1.คะแนนรวมสมรรถภาพทางสมอง (TMSE)',
        ten_valiable_1_2: '1.คะแนนรวมสมรรถภาพทางสมอง (TMSE)',
        ten_valiable_2_1: '2.คะแนนประเมินความเครียด (ST5)',
        ten_valiable_2_2: '2.คะแนนประเมินความเครียด (ST5)',
        ten_valiable_3_1: '3.ปวดหลังส่วนเอว',
        ten_valiable_3_2: '3.ปวดหลังส่วนเอว',
        ten_valiable_4_1: '4.ปวดข้อเข่า',
        ten_valiable_4_2: '4.ปวดข้อเข่า',
        ten_valiable_5_1: '5.ปวดสะโพก/ต้นขา',
        ten_valiable_5_2: '5.ปวดสะโพก/ต้นขา',
        ten_valiable_6_1: '6.ปวดไหล่',
        ten_valiable_6_2: '6.ปวดไหล่',
        ten_valiable_7_1: '7.ปวดสะบัก',
        ten_valiable_7_2: '7.ปวดสะบัก',
        ten_valiable_8_1: '8.ปวดคอ',
        ten_valiable_8_2: '8.ปวดคอ',
        ten_valiable_9_1: '9.ปวดข้อเท้า/เท้า',
        ten_valiable_9_2: '9.ปวดข้อเท้า/เท้า',
        ten_valiable_10_1: '10.สามารถยกของที่มีน้ำหนักมากกว่า 15 กิโลกรัมได้ ',
        ten_valiable_10_2: '10.สามารถยกของที่มีน้ำหนักมากกว่า 15 กิโลกรัมได้ '
      },
      isModal: false
    }
  },
  computed: {
    graphStyle() {
      return {
        height: '500px'
      }
    },
    userLevel() {
      const { codeId, userLevel } = this.$store.getters['oauth/session']
      return userLevel
    },
    userArea() {
      const { codeId, userLevel, area } = this.$store.getters['oauth/session']
      if (userLevel == 'AC') {
        return this.areaId == 'ALL' ? null : this.areaId
      } else {
        return area?.codeId
      }
    },
    areas() {
      return this.$store.getters['area/getAreasHasAllLabel']
    }
  },
  watch: {
    areaId:{
      handler(newVal, oldVal) {
        console.log('newVal ::==', newVal)
        this.processedImages();
      },
    }
  },

  async created() {
    try {
      await this.$store.dispatch('area/fetchAreasOrderNameTh')
      this.fetchData()
    } catch (error) {
      this.$toast.add({
        severity: 'error',
        summary: 'เกิดข้อผิดพลาดจากระบบ',
        detail: 'ระบบไม่สามารถให้บริการได้ ขออภัยในความไม่สะดวก',
        life: 3000
      })
    }
  },
  methods: {
    processedImages() {
      if (this.areaId === 'ALL') {
        this.images = {
          ten_valiable_1_1: '1.คะแนนรวมสมรรถภาพทางสมอง (TMSE)',
          ten_valiable_1_2: '1.คะแนนรวมสมรรถภาพทางสมอง (TMSE)',
          ten_valiable_2_1: '2.คะแนนประเมินความเครียด (ST5)',
          ten_valiable_2_2: '2.คะแนนประเมินความเครียด (ST5)',
          ten_valiable_3_1: '3.ปวดหลังส่วนเอว',
          ten_valiable_3_2: '3.ปวดหลังส่วนเอว',
          ten_valiable_4_1: '4.ปวดข้อเข่า',
          ten_valiable_4_2: '4.ปวดข้อเข่า',
          ten_valiable_5_1: '5.ปวดสะโพก/ต้นขา',
          ten_valiable_5_2: '5.ปวดสะโพก/ต้นขา',
          ten_valiable_6_1: '6.ปวดไหล่',
          ten_valiable_6_2: '6.ปวดไหล่',
          ten_valiable_7_1: '7.ปวดสะบัก',
          ten_valiable_7_2: '7.ปวดสะบัก',
          ten_valiable_8_1: '8.ปวดคอ',
          ten_valiable_8_2: '8.ปวดคอ',
          ten_valiable_9_1: '9.ปวดข้อเท้า/เท้า',
          ten_valiable_9_2: '9.ปวดข้อเท้า/เท้า',
          ten_valiable_10_1: '10.สามารถยกของที่มีน้ำหนักมากกว่า 15 กิโลกรัมได้ ',
          ten_valiable_10_2: '10.สามารถยกของที่มีน้ำหนักมากกว่า 15 กิโลกรัมได้ '
        };
      } else if (this.areaId == '1') {
        this.images = {
          ten_valiable_1_1: '1.คะแนนรวมสมรรถภาพทางสมอง (TMSE)',
          ten_valiable_1_2: '1.คะแนนรวมสมรรถภาพทางสมอง (TMSE)',
          ten_valiable_2_1: '2.คะแนนประเมินความเครียด (ST5)',
          ten_valiable_2_2: '2.คะแนนประเมินความเครียด (ST5)',
          ten_valiable_3_1: '3.ปวดหลังส่วนเอว',
          ten_valiable_3_2: '3.ปวดหลังส่วนเอว',
          ten_valiable_4_1: '4.ปวดสะบัก',
          ten_valiable_4_2: '4.ปวดสะบัก',
          ten_valiable_5_1: '5.ปวดข้อเข่า',
          ten_valiable_5_2: '5.ปวดข้อเข่า',
          ten_valiable_6_1: '6.ปวดสะโพก/ต้นขา',
          ten_valiable_6_2: '6.ปวดสะโพก/ต้นขา',
          ten_valiable_7_1: '7.ปวดไหล่',
          ten_valiable_7_2: '7.ปวดไหล่',
          ten_valiable_8_1: '8.สามารถยกของที่มีน้ำหนักมากกว่า 15 กิโลกรัมได้',
          ten_valiable_9_1: '9.ปวดข้อมือ/มือ',
          ten_valiable_9_2: '9.ปวดข้อมือ/มือ',
          ten_valiable_10_1: '10.ปวดคอ',
          ten_valiable_10_2: '10.ปวดคอ'
        };
      } else {
        this.images = {
          ten_valiable_1_1: '1.คะแนนรวมสมรรถภาพทางสมอง (TMSE)',
          ten_valiable_1_2: '1.คะแนนรวมสมรรถภาพทางสมอง (TMSE)',
          ten_valiable_2_1: '2.คะแนนประเมินความเครียด (ST5)',
          ten_valiable_2_2: '2.คะแนนประเมินความเครียด (ST5)',
          ten_valiable_3_1: '3.ปวดหลังส่วนเอว',
          ten_valiable_3_2: '3.ปวดหลังส่วนเอว',
          ten_valiable_4_1: '4.ปวดข้อเข่า',
          ten_valiable_4_2: '4.ปวดข้อเข่า',
          ten_valiable_5_1: '5.ปวดสะโพก/ต้นขา',
          ten_valiable_5_2: '5.ปวดสะโพก/ต้นขา',
          ten_valiable_6_1: '6.ปวดข้อเท้า/เท้า',
          ten_valiable_6_2: '6.ปวดข้อเท้า/เท้า',
          ten_valiable_7_1: '7.ปวดไหล่',
          ten_valiable_7_2: '7.ปวดไหล่',
          ten_valiable_8_1: '8.สามารถยกของที่มีน้ำหนักมากกว่า 15 กิโลกรัมได้',
          ten_valiable_9_1: '9.ปวดคอ',
          ten_valiable_9_2: '9.ปวดคอ',
          ten_valiable_10_1: '10.หกล้มกี่ครั้ง',
          ten_valiable_10_2: '10.หกล้มกี่ครั้ง'
        };
      }
    },
    async fetchData() {
      await this.$store.dispatch('word/fetchGraphWords', {
        areaId: this.userArea
      })
      this.getGraphBarplot()
      //**************** get graph api*************** */
    },
    getGraphBarplot() {
      const { VUE_APP_ML_ENDPOINT } = process.env
      let delay = 500
      let idx = 0
      Object.keys(this.images).forEach((name) => {
        setTimeout(
          async () => {
            this.plots[name] = 'img/analytics/'+this.areaId+'/'+name+'.png?'+new Date().getTime();
          },
          idx == 0 ? 0 : delay + idx * 500
        )
      })
    }
  },
  components: {
    ModalHealthSum,
    MoveToTopPage
  }
}
</script>

<style>
</style>